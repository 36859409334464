<script>
/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
  },

  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;
      console.log(event.target.nextSibling.nextSibling);
      console.log(event.target.lastChild);
      console.log(event.target.childNodes);

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
      id="topnav"
      class="defaultscroll sticky"
      :class="{ 'bg-white': isWhiteNavbar === true }"
    >
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo pt-1" to="/" v-if="navLight !== true">
            <img src="images/logo-dark.png" height="80" alt="" />
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img
              src="images/logo-dark.png"
              class="l-dark"
              height="100"
              alt=""
            />
            <img
              src="images/logo-light.png"
              class="l-light"
              height="100"
              alt=""
            />
          </router-link>
        </div>

        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a
              class="navbar-toggle"
              @click="toggleMenu()"
              :class="{ open: isCondensed === true }"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            :class="{ 'nav-light': navLight === true }"
          >
            <li>
              <router-link to="/" class="side-nav-link-ref">Home</router-link>
            </li>

            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Services</a
              ><span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/cloud" class="side-nav-link-ref"
                        >Cloud</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/data-center" class="side-nav-link-ref"
                        >Data Center</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/enterprise-network"
                        class="side-nav-link-ref"
                        >Entreprise Network</router-link
                      >
                    </li>

                    <li>
                      <router-link
                        to="/digital-transformation"
                        class="side-nav-link-ref"
                        >Digital Transformation</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/security" class="side-nav-link-ref"
                        >Security</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Solutions</a
              ><span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/index-saas" class="side-nav-link-ref"
                        >Solution 1</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/index-classic-saas"
                        class="side-nav-link-ref"
                        >Solution 2</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/index-agency" class="side-nav-link-ref"
                        >Solution 3</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/index-apps" class="side-nav-link-ref"
                        >Solution 4</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/index-classic-app"
                        class="side-nav-link-ref"
                        >Solution 5</router-link
                      >
                    </li>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li>
                      <router-link
                        to="/index-email-inbox"
                        class="side-nav-link-ref"
                        >Email Inbox
                        <span class="badge badge-danger rounded"
                          >v2.5</span
                        ></router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li> -->
            <!--
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Public Sector</a
              ><span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/index-saas" class="side-nav-link-ref"
                        >Federal</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/index-classic-saas"
                        class="side-nav-link-ref"
                        >Higher Education</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/index-agency" class="side-nav-link-ref"
                        >K-12</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/index-apps" class="side-nav-link-ref"
                        >Public Safety</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/index-classic-app"
                        class="side-nav-link-ref"
                        >State and local</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li> -->

            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">Company</a
              ><span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/company" class="side-nav-link-ref"
                        >About US</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/careers" class="side-nav-link-ref"
                        >Careers</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <router-link to="/contact" class="side-nav-link-ref"
                >Contact</router-link
              >
            </li>
            <li>
              <div class="buy-button">
                <a
                  href="tel:12029776666"
                  class="btn call-button"
                  style="font-size:11.5px"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-primary': navLight !== true,
                  }"
                  ><i class="mdi mdi-phone"></i> (202) 977-6666</a
                >
              </div>
            </li>
          </ul>

          <!--end navigation menu-->

          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>
